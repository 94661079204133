import React from "react";
import { Helmet } from "react-helmet";
import Logo from "../../images/logo.svg";
import jackson from "../../images/people/jludwig.jpeg";
import abhi from "../../images/people/ashandilya.jpg";
import honghao from "../../images/people/hli.jpeg";
import ethan from "../../images/people/estone.jpg";
import ran from "../../images/people/rguo.jpg";
import nbordeau from "../../images/people/nbordeau.jpg";
import { Disclosure } from "@headlessui/react";
import { AcademicCapIcon, ChevronDownIcon } from "@heroicons/react/24/outline";

import Footer from "../../components/common/Footer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Internships() {
  const faqs = [
    {
      question: "Can I do my internship remotely?",
      answer:
        "Unfortunately, right now, we do not offer remote internships. We've found that at the pace we iterate, when we have a mix of in-office and remote interns, the remote interns fall behind quickly. We offer flexible work schedules and subsidized housing to help make a move easier.",
    },
    {
      question: "Does Green Action Studio have housing for interns?",
      answer:
        "We have partnered with a housing provider close to our office for interns that we can refer you to. In addition, the accommodations have access to public transportation to make the journey easier.",
    },
    {
      question: "Does Green Action Studio sponsor international employees?",
      answer:
        "Yes. Once an intern becomes a full-time employee, we do offer H-1B and H-1B1 sponsorship.",
    },
    {
      question:
        "Can I become a full-time software engineer after the internship ends?",
      answer:
        "Absolutely! You will participate in multiple position planning meetings at Green Action Studio during your internship (30-day, 90-day, and 180-day). During these meetings, we frequently will make full-time job offers to interns.",
    },
  ];

  const people = [
    {
      name: "Nick Bordeau",
      role: "Director of Software Engineering",
      imagePosition: "object-top",
      imageUrl: nbordeau,
      bio: "Nick has been in software for the last decade and is passionate about EV Charging (being a Tesla driver himself). He specializes in developing high-performing teams and mentoring those around him to be their best version. When he's not in the office, he is on a sports field or playing in the backyard with his dog, Boris. ",
    },
    {
      name: "Ethan Stone",
      role: "Software Team Lead, Internal Tools",
      imagePosition: "object-center",
      imageUrl: ethan,
      bio: "Ethan is a tinkerer who is constantly perfecting his craft. He is always trying to find ways for systems to work more efficiently. When he's not wielding a keyboard, he's an avid basketball fan (go Bucks!) and a growing amateur chef.",
    },
    {
      name: "Ran Guo",
      role: "Software Team Lead, Hardware Communication",
      imagePosition: "object-bottom",
      imageUrl: ran,
      bio: "Ran started as an intern with GAS and has not stopped growing since. He strives to understand complex topics on a deeper level and then help his team do the same. When he's not at work, he's at home with his wife and daughter being the best dad he can be.",
    },
    {
      name: "Abhishek Shandilya",
      role: "Software Team Lead, Partner Integrations",
      imagePosition: "object-center",
      imageUrl: abhi,
      bio: "A software generalist who loves web development, Bitcoin and electric vehicles. You can find Abhi reading books, swimming, hiking or playing basketball when he's away from his laptop.",
    },
  ];

  const internshipSteps = [
    {
      name: "General Coding Assessment",
      description:
        "A 60-minute coding assessment that verifies you understand JavaScript basics. The evaluation includes coding and long-form questions.",
    },
    {
      name: "Technical Interview",
      description:
        "A 60-minute meet and greet with one of our team leads. Together you will dive into your long-form answers on the call and complete a simple coding challenge.",
    },
    {
      name: "Culture Meet and Greet",
      description:
        "A 30-minute meet and greet with the technical recruiting team and the Director of Software Engineering discussing the work culture at GAS.",
    },
    {
      name: "Internship Offer",
      description:
        "You've made it! You received an internship offer letter and resources to help make your onboarding seamless.",
    },
  ];

  return (
    <div className="relative overflow-hidden">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Software Internship Program | Green Action Studio</title>
        <meta
          name="description"
          content="We're building the next generation of software engineers to solve the next generation of problems"
        ></meta>
      </Helmet>
      <main>
        <section className="relative bg-gray-900 pt-10 pb-32 sm:pt-16 lg:overflow-hidden lg:py-6">
          <div className="mx-auto max-w-7xl lg:px-8">
            <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 sm:text-center lg:flex lg:items-center lg:justify-center lg:px-0">
              <div className="lg:py-24">
                <a href="/" className="mx-auto flex h-36 w-36">
                  <span className="sr-only">Green Action Studio</span>
                  <img src={Logo} alt="Green Action Studio" />
                </a>
                <h1 className="pt-6 text-2xl font-extrabold tracking-tight text-gray-100 sm:mt-5 lg:mt-6 lg:text-4xl">
                  <span className="block">Join the Next Generation of</span>
                  <span className="block text-4xl text-primary-500 lg:text-6xl">
                    Problem Solvers
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-100 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  At Green Action Studio (GAS), our team owns the distributed
                  software that provides megawatts of energy to thousands of
                  electric vehicle (EV) drivers across the United States daily.
                  We're hyper-focused on creating a green transportation future
                  and are looking for interns who feel the same way.
                </p>
                <p className="mt-3 text-base text-gray-100 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  As a software team member with GAS, you will get hands-on
                  experience with some of the most cutting-edge software
                  patterns, including building scalable event-driven systems.
                  From planning to deploying cloud resources, you will come up
                  close and personal with all stages of software development. As
                  you rapidly evolve your skillset, you will tackle real-world
                  challenges individually and as a team.
                </p>
                <div className="pt-8">
                  <button
                    type="button"
                    className="inline-flex items-center rounded border border-transparent bg-primary-500 px-8 py-3 text-lg text-white shadow-sm transition-all hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                    onClick={(e) => {
                      let app = document.getElementById(
                        "internship-application"
                      );
                      app && app.scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    <AcademicCapIcon
                      className="mr-2 hidden h-6 lg:block"
                      aria-hidden="true"
                    />
                    Apply for Internship
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/** Testimonial Section */}
        <section className="bg-primary-500">
          <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">
            <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:border-r md:border-r-2 md:border-primary-700 md:py-16 md:pl-0 md:pr-10 lg:pr-16">
              <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
                <div className="relative text-lg font-medium text-white md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-primary-600"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                    aria-hidden="true"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                    During my time as an intern at Green Action Studio, I
                    obtained first-hand experience in designing and implementing
                    scalable, modular production software. I learned many
                    different practical skills in software engineering, such as
                    how to plan and architect a system, testing, writing
                    maintainable code, UX design, and more. The knowledge I
                    gained at GAS has made me a better problem solver and more
                    able to pursue my passion for programming.
                  </p>
                </div>
                <footer className="mt-8">
                  <div className="flex items-start">
                    <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                      <img
                        className="h-12 w-12 rounded-full"
                        src={jackson}
                        alt="Jackson Ludwig"
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-base font-medium text-white">
                        Jackson Ludwig
                      </div>
                      <div className="text-base font-medium text-slate-700">
                        Stony Brook University
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
            <div className="border-t-2 border-primary-600 py-12 px-4 sm:px-6 md:border-l-2 md:border-t-0 md:py-16 md:pr-0 md:pl-10 lg:pl-16">
              <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
                <div className="relative text-lg font-medium text-white md:flex-grow">
                  <svg
                    className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-2 transform text-primary-600"
                    fill="currentColor"
                    viewBox="0 0 32 32"
                  >
                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                  </svg>
                  <p className="relative">
                    My internship at Green Action Studio provided me with
                    valuable industry experience working with event-driven
                    architecture. The thing that impressed me the most is how
                    fast and agile we were when it came to decision making on
                    what technologies best fit. It was my true honor to work
                    with such talented colleagues.
                  </p>
                </div>
                <footer className="mt-8">
                  <div className="flex items-start">
                    <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                      <img
                        className="h-12 w-12 rounded-full"
                        src={honghao}
                        alt="Honghao Li"
                      />
                    </div>
                    <div className="ml-4">
                      <div className="text-base font-medium text-white">
                        Honghao Li
                      </div>
                      <div className="text-base font-medium text-slate-700">
                        Rensselaer Polytechnic Institute
                      </div>
                    </div>
                  </div>
                </footer>
              </blockquote>
            </div>
          </div>
        </section>

        {/** Team Section */}
        <section className="bg-white">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
            <div className="space-y-12">
              <h2 className="text-3xl font-bold tracking-tight text-primary-500 sm:text-4xl lg:text-6xl">
                Meet our leadership
              </h2>

              <ul
                role="list"
                className="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
              >
                {people.map((person) => (
                  <li key={person.name}>
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                      <div className="aspect-w-3 aspect-h-2 h-0 sm:aspect-w-3 sm:aspect-h-4">
                        <img
                          className={`rounded-lg object-cover shadow-lg ${person.imagePosition}`}
                          src={person.imageUrl}
                          alt=""
                        />
                      </div>
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div>
                            <h3 className="text-2xl font-bold text-slate-600">
                              {person.name}
                            </h3>
                            <p className="text-lg text-primary-500">
                              {person.role}
                            </p>
                          </div>
                          <div className="text-sm">
                            <p className="text-gray-500">{person.bio}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>

        {/** Internship Process */}
        <section className="overflow-hidden bg-gray-50 shadow-inner">
          <div className="relative mx-auto px-4 pt-24 pb-36 sm:px-6 lg:px-8">
            <svg
              className="absolute top-0 left-full -translate-x-1/2 -translate-y-3/4 transform lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/3"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
              />
            </svg>

            <div className="relative lg:grid lg:grid-cols-3 lg:gap-x-8">
              <div className="lg:col-span-1">
                <h2 className="text-3xl font-bold tracking-tight text-slate-700 sm:text-4xl lg:text-6xl">
                  The Internship Process
                </h2>
              </div>
              <dl className="mt-10 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 sm:space-y-0 lg:col-span-2 lg:mt-0">
                {internshipSteps.map((feature, index) => (
                  <div key={feature.name}>
                    <dt>
                      <div className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary-500  text-xl font-medium text-white">
                        {`${index + 1}`}
                      </div>
                      <p className="mt-5 text-xl leading-6 text-slate-700">
                        {feature.name}
                      </p>
                    </dt>
                    <dd className="mt-2 text-base text-gray-500">
                      {feature.description}
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>

        {/** Internship Application */}
        {/* <section id="internship-application" className="border-t-2 pt-20 pb-8">
          <iframe
            className="w-full border-0"
            height="980px"
            src="https://notionforms.io/forms/gas-intern-application"
          ></iframe>
        </section> */}
        <div className="container mx-auto py-10 px-4 text-center">
          <p className="text-center">
            We are currently not accepting internship applications at this time.
            Please be on the lookout for our next hiring hackathon in Summer
            2023.
          </p>
        </div>

        {/** FAQs */}
        <section className="bg-gray-50 shadow-inner">
          <div className="mx-auto max-w-7xl py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-3xl divide-y-2 divide-gray-200">
              <h2 className="text-center text-3xl font-extrabold text-slate-700 sm:text-4xl">
                Frequently asked questions
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {faqs.map((faq) => (
                  <Disclosure as="div" key={faq.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                            <span className="font-medium text-slate-700">
                              {faq.question}
                            </span>
                            <span className="ml-6 flex h-7 items-center">
                              <ChevronDownIcon
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-6 w-6 transform"
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-500">
                            {faq.answer}
                          </p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
}
